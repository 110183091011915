import cx from 'clsx';
import type { ReactElement } from 'react';
import { Avatar, BadgeStatus, type StatusType } from '@repo/design-system-kit';
import styles from './header.strict-module.css';

export interface HeaderBadge {
  type: StatusType;
  text: string;
}

export interface HeaderProps {
  amount: string;
  amountIsDisabled?: boolean;
  avatarHasBorder?: boolean;
  avatarIsDisabled?: boolean;
  avatarSrc: string;
  badge?: HeaderBadge;
  name: string;
  children?: ReactElement;
}

export function Header({
  amount,
  amountIsDisabled = false,
  avatarHasBorder = false,
  avatarIsDisabled = false,
  avatarSrc,
  badge,
  name,
  children,
}: HeaderProps): ReactElement {
  return (
    <div className={styles.header} data-test-header>
      <div className={styles.info}>
        <div className={cx('mb-4', styles['info-avatar'])}>
          <Avatar
            alt={name}
            isDisabled={avatarIsDisabled}
            src={avatarSrc}
            withBorder={avatarHasBorder}
          />
          {badge ? (
            <BadgeStatus data-test-header-info-badge-status level={badge.type} text={badge.text} />
          ) : null}
        </div>
        <p className={cx('body-1', 'mb-12', styles['info-name'])} data-test-header-info-name>
          {name}
        </p>
        <h2
          className={cx('title-2', amountIsDisabled && styles['info-amount-disabled'])}
          data-test-header-info-amount
        >
          {amount}
        </h2>
      </div>
      {children}
    </div>
  );
}
